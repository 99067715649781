import React from 'react';
import cn from 'classnames';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
  UploadedImage,
} from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import { CallToAction } from '../../../CallToActionGroup';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import { Paragraph, ModuleHeader } from '../../components';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import styles from './ImageModule.module.scss';

export enum ImageModulePositions {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum ImageModuleSize {
  STANDARD = 'STANDARD',
  HALF = 'HALF',
  THIRD = 'THIRD',
  QUARTER = 'QUARTER',
}

export enum ImageModuleWidth {
  STANDARD = 'STANDARD',
  PAGE = 'PAGE',
}

export interface ImageModuleType extends HubModule {
  '@type': HubModuleTypes.Image;
  image: UploadedImage;
  imagePosition: ImageModulePositions;
  imageSize: ImageModuleSize;
  imageWidth: ImageModuleWidth;
  link: HubModuleCTA;
  title: string;
  text: string;
}

export interface ImageModuleProps {
  module: ImageModuleType;
}

export default function ImageModule({
  module: {
    '@type': type,
    title,
    imagePosition,
    imageSize,
    imageWidth,
    image,
    text,
    link,
    elementId,
    visualStyle,
  },
  testId,
}: PropsWithTestId<ImageModuleProps>) {
  const pageWidth = imageWidth === ImageModuleWidth.PAGE;
  const pageWidthHolderClassName = cn(styles.holder, {
    [styles.pageWidthHolder]: pageWidth,
  });
  const pageWidthClassName = cn({
    [styles.pageWidthText]: pageWidth,
  });
  const dynamicImageSizeClassName = cn('col-xs-12', {
    'col-lg-6': imageSize === 'STANDARD' || imageSize === 'HALF',
    'col-lg-4': imageSize === 'THIRD',
    'col-lg-3': imageSize === 'QUARTER',
  });
  const dynamicContentSizeClassName = cn('col-xs-12', {
    'col-lg-6': imageSize === 'STANDARD' || imageSize === 'HALF',
    'col-lg-8': imageSize === 'THIRD',
    'col-lg-9': imageSize === 'QUARTER',
  });

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row">
          {imagePosition === ImageModulePositions.LEFT && (
            <>
              <div className={dynamicImageSizeClassName}>
                <div className={styles.rightPadding}>
                  <ImageTag className={styles.holder} src={image.path} alt="" />
                </div>
              </div>
              <div className={dynamicContentSizeClassName}>
                <div className={cn(styles.contentWrapper, styles.leftPadding)}>
                  <ModuleHeader
                    title={title}
                    text={text}
                    link={link}
                    lineAfterHeading={!pageWidth}
                  />
                </div>
              </div>
            </>
          )}
          {imagePosition === ImageModulePositions.RIGHT && (
            <>
              <div className={dynamicContentSizeClassName}>
                <div className={cn(styles.contentWrapper, styles.rightPadding)}>
                  <ModuleHeader
                    title={title}
                    text={text}
                    link={link}
                    lineAfterHeading={!pageWidth}
                  />
                </div>
              </div>
              <div className={dynamicImageSizeClassName}>
                <div className={styles.leftPadding}>
                  <ImageTag
                    className={cn(styles.holder, styles.imageOffset)}
                    src={image.path}
                    alt=""
                  />
                </div>
              </div>
            </>
          )}
          {imagePosition === ImageModulePositions.TOP && (
            <div className="col-xs-12 col-sm-12 col-lg-12">
              <ModuleHeader
                title={title}
                lineAfterHeading={!pageWidth}
                className={pageWidthClassName}
              />
              <div className={styles.imagePadding}>
                <ImageTag
                  className={pageWidthHolderClassName}
                  src={image.path}
                  alt=""
                />
              </div>
              {text && <Paragraph text={text} className={pageWidthClassName} />}
              {link?.type && (
                <CallToAction
                  link={link}
                  variant="contained"
                  align="center"
                  className={styles.ctaButton}
                  data-testid="hub-module-link"
                />
              )}
            </div>
          )}
          {imagePosition === ImageModulePositions.BOTTOM && (
            <div className="col-xs-12 col-sm-12 col-lg-12">
              <ModuleHeader
                title={title}
                lineAfterHeading={!pageWidth}
                className={pageWidthClassName}
              />
              {text && (
                <div className={styles.paragraphPadding}>
                  <Paragraph text={text} className={pageWidthClassName} />
                </div>
              )}
              <div className={styles.imagePadding}>
                <ImageTag
                  className={pageWidthHolderClassName}
                  src={image.path}
                  alt=""
                />
              </div>
              {link?.type && (
                <CallToAction
                  link={link}
                  variant="contained"
                  align="center"
                  data-testid="hub-module-link"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </HubModuleWrapper>
  );
}

ImageModule.defaultProps = {
  testId: 'hub-image-module',
};
